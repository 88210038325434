import React, { useState, useEffect } from "react"
import styled from "styled-components"

import { StoryItem } from "./StoryItem"
import MobileStoryItem from "./MobileStoryItem"
import SideBannerImage from "../SideBanner/SideBannerImage"
import { mediaMax } from "../../utils/breakpoints"
import Img from "gatsby-image"

import {
  Space,
  Scroller,
  Room,
  RoomSide,
  Wallpaper,
  RoomSideEnum,
  useRoomMovement,
  RoomHoverText,
} from "../RoomExperience"

import Video from "../Video/Video"
import { GlitchEffect } from "../GlitchEffect/Glitch"
import { Cube } from "../Cube/Cube"
import CloseIcon from "../../images/Close.svg"
import Window from "../Svg/Window"

interface StoryItemProps {
  backgroundImage?: any
  speakerName?: string
  companyName?: string
  videolink?: string
  order?: number
}

const StoryRooms = ({
  data,
  transitionStatus,
  enableTilt,
  showInfo,
  roomNumber,
  onInfo,
}: any) => {

  const { scrollerRef } = useRoomMovement({
    enableTilt,
    enableTopView: showInfo,
    transitionStatus,
  })

  const [ActiveItem, setActiveItem] = useState<StoryItemProps>({})

  const handleClick = (item: StoryItemProps) => {
    onInfo()
    setActiveItem(item)
  }

  const handleHover = (item: StoryItemProps) => {
    setActiveItem(item)
  }
  return (
    <>
      <Space>
        <Scroller ref={scrollerRef}>
          <Room>
            <RoomSide
              className={RoomSideEnum.Right}
              style={{ overflow: "hidden" }}
            >
               <Wallpaper
                fluid={data.concreteWall.childImageSharp.fluid}
                backgroundColor="#585858"
              />
            </RoomSide>

            <RoomSide className={RoomSideEnum.Left}>
            <CustomGradient
                gradientCode="linear-gradient(-90deg, rgba(0,130,133,0) 0%, rgba(0,130,133,0.9) 100%)"
              /> 
              <Wallpaper
                fluid={data.concreteWall.childImageSharp.fluid}
                backgroundColor="#585858"
              />

              {/* <StoryItem
                xPos="35%"
                heightPercentage="60%"
                widthPercentage="20%"
                handleHover={() => handleHover(data.allStory.edges[3].node)}
                handleClick={() => handleClick(data.allStory.edges[3].node)}
              >
                {data.allStory.edges[3].node.backgroundImage != null && (
                  <GlitchContainer>
                    <GlitchEffect
                      backgroundImage={
                        data.allStory.edges[3].node.backgroundImage
                          .childImageSharp.fluid
                      }
                      small={true}
                    ></GlitchEffect>
                  </GlitchContainer>
                )}
              </StoryItem>
              <StoryItem
                xPos="10%"
                heightPercentage="60%"
                widthPercentage="20%"
                handleHover={() => handleHover(data.allStory.edges[4].node)}
                handleClick={() => handleClick(data.allStory.edges[4].node)}
              >
                {data.allStory.edges[4].node.backgroundImage != null && (
                  <GlitchContainer>
                    <GlitchEffect
                      backgroundImage={
                        data.allStory.edges[4].node.backgroundImage
                          .childImageSharp.fluid
                      }
                      small={true}
                    ></GlitchEffect>
                  </GlitchContainer>
                )}
              </StoryItem> */}
            </RoomSide>
            <RoomSide className={RoomSideEnum.Bottom}>
              <WallpaperFloor
                fluid={data.floor.childImageSharp.fluid}
                backgroundColor="#585858"
              />
            </RoomSide>
            <RoomSide className={RoomSideEnum.Top}>
              <WallpaperTop
                fluid={data.floor.childImageSharp.fluid}
                backgroundColor="#585858"
              />
            </RoomSide>
            <RoomSide className={RoomSideEnum.Back}>
              <WallpaperBack
                fluid={data.concreteWall.childImageSharp.fluid}
                backgroundColor="#585858"
              />
              {/* <StoryItem
                xPos="75%"
                heightPercentage="60%"
                widthPercentage="20%"
                handleHover={() => handleHover(data.allStory.edges[2].node)}
                handleClick={() => handleClick(data.allStory.edges[2].node)}
              >
                {data.allStory.edges[2].node.backgroundImage != null && (
                  <GlitchContainer>
                    <GlitchEffect
                      backgroundImage={
                        data.allStory.edges[2].node.backgroundImage
                          .childImageSharp.fluid
                      }
                      small={true}
                    ></GlitchEffect>
                  </GlitchContainer>
                )}
              </StoryItem> */}
              {/* <StoryItem
                xPos="1%"
                heightPercentage="60%"
                widthPercentage="20%"
                handleHover={() => handleHover(data.allStory.edges[0].node)}
                handleClick={() => handleClick(data.allStory.edges[0].node)}
              >
                {data.allStory.edges[0].node.backgroundImage != null && (
                  <GlitchContainer>
                    <GlitchEffect
                      backgroundImage={
                        data.allStory.edges[0].node.backgroundImage
                          .childImageSharp.fluid
                      }
                      small={true}
                    ></GlitchEffect>
                  </GlitchContainer>
                )}
              </StoryItem> */}
              <StoryItem
                xPos="28%"
                heightPercentage="49%"
                widthPercentage="40%"
                handleHover={() => handleHover(data.allStory.edges[0].node)}
                handleClick={() => handleClick(data.allStory.edges[0].node)}
              >
                {data.allStory.edges[0].node.backgroundImage != null && (
                  <GlitchContainer>
                    <GlitchEffect
                      backgroundImage={
                        data.allStory.edges[0].node.backgroundImage
                          .childImageSharp.fluid
                      }
                      small={true}
                    ></GlitchEffect>
                  </GlitchContainer>
                )}
              </StoryItem>
            </RoomSide>
          </Room>

          <RoomHoverText
            title={ActiveItem.speakerName}
            description={ActiveItem.companyName}
          />
        </Scroller>
      </Space>

      {/* TODO */}
      {/* <Content ref={contentRef} className={ContentActive == true ? "active" : ""}> */}
      <Content className={showInfo ? "active" : ""}>
        <Video
          videoSrcURL={ActiveItem.videolink}
          height="50vh"
          width="50vw"
          centerScreen={true}
        />
      </Content>
    </>
  )
}

interface GradientProps {
  gradientCode?: string
}
const CustomGradient = styled.div<GradientProps>`
  height: 100%;
  width: 100%;
  position: absolute;

  transform: translate3d(0px, 0px, 5px);

  &:after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: ${({ gradientCode }) => gradientCode};
  }
`

const MobileStoryItemContainer = styled.div`
  margin-top: 35vh;
  width: 95vw;
  margin-left: 33vw;

  @media ${mediaMax.tablet} {
    margin-left: 0;
  }
`

const MobileContainer = styled.div`
  padding-bottom: 30px;

  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
`

const WallpaperBack = styled(Wallpaper)`
  background-size: auto 100%;
  background-repeat: repeat-x;
  background-position: left;
  background-position: center;
`
const WallpaperFloor = styled(Wallpaper)`
  background-repeat: no-repeat;
  filter: brightness(70%);
  background-size: 100% 100%;
`

const WallpaperTop = styled(Wallpaper)`
  background-repeat: no-repeat;
  background-size: 100% 100%;
`

const CloseVideoButton = styled.button`
  position: absolute;
  top: 30px;
  right: 20px;
  height: 50px;
  width: 50px;
  background-color: white;
  border-radius: 50%;
  border: none;
  z-index: 202;

  &:after {
    content: "";
    position: absolute;
    left: 15px;
    top: 14px;
    height: 20px;
    width: 20px;
    background-image: url(${CloseIcon});
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 50%;
  }
`

const GlitchContainer = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
`

const Content = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  padding: 2vw;
  background:rgba(0,130,133,0.5);
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.6s ease;
  z-index: 202;

  &.active {
    opacity: 1;
    pointer-events: auto;
  }

  h1 {
    color: white;
  }
`

export default StoryRooms
