import React from "react"
import styled from 'styled-components';

interface VideoProps{
  videoSrcURL? : string,
  videoTitle? : string, 
  height? : string,
  width? : string,
  centerScreen? : boolean
}

const Video = ({ videoSrcURL, videoTitle, height, width, centerScreen } : VideoProps) => (
  <StyledVideo centerScreen={centerScreen}>
    <iframe
      src={videoSrcURL}
      title={videoTitle}
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
      frameBorder="0"
      allowFullScreen
      style={{height: `${height}`, width: `${width}`}}
    />
  </StyledVideo>
)

const StyledVideo = styled.div<VideoProps>`
  display:flex;
  flex-direction:row;
  justify-content:center;
  align-items:center;
  height:100%;
  width:100%;

  ${({ centerScreen }) => {
    if(centerScreen){
      return`
        height:100vh;
        width:100vw;
      `
    }
  }}

`

export default Video