import React from "react"
import styled from "styled-components"

import Video from "../Video/Video"

const MobileStoryItem = ({ data }: any) => {
  return (
    <Container>
      <h2>{data.speakerName}</h2>
      <StoryItem>
        <Video videoSrcURL={data.videolink} height="100%" width="100%" />
      </StoryItem>
    </Container>
  )
}

const Container = styled.div`
  h2 {
    font-family: ${({ theme }) => theme.fonts.fontFamilyPrimary};
    text-align: center;
  }
`
const StoryItem = styled.div`
  height: 25vh;
  width: 100%;
  margin-bottom: 20px;
`

export default MobileStoryItem
