import React, { useState, useEffect } from "react"
import Layout, { MyLocationEnum } from "../components/Layout"
import SEO, { MetaOG } from "../components/Seo"
import { graphql } from "gatsby"
import StoryRooms from '../components/StoryRooms'
import { TransitionStatus } from "react-transition-group/Transition"
import MobileStoryRoom from "../components/StoryRooms/MobileStoryRoom"
import BannerImage from "../images/haustradstefnaSocial.png"

interface StoryRoomProps {
  data: any,
  location: Location,
  transitionStatus: TransitionStatus,
}

export default ({ data, location, transitionStatus }: StoryRoomProps) => {
  const [showInfo, setShowInfo] = useState(false)
  const [enableTilt, setEnableTilt] = useState(true)

  const seo_og: MetaOG = {
    title: "Nærmynd",
    description: "",
  }

  const [showMobile, setShowMobile] = useState(false)
  useEffect(() => {
    var resp = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)
    setShowMobile(resp)
  })

  if(!showMobile) {
    return (
      <Layout 
        myLocation={MyLocationEnum.Bransasogur}
        location={location}
        transitionStatus={transitionStatus}
        showInfo={showInfo}
        onClose={() => setShowInfo(false)}
      >
        <SEO
          title="Nærmynd"
          description=""
          og={seo_og}
        />
        <StoryRooms 
          active={true}
          data={data}
          enableTilt={enableTilt} 
          showInfo={showInfo} 
          transitionStatus={transitionStatus}
          onInfo={() => setShowInfo(!showInfo)}
        />
      </Layout>
    )
  } else {
    return (
      <Layout 
        myLocation={MyLocationEnum.Bransasogur}
        location={location}
        transitionStatus={transitionStatus}
        showInfo={showInfo}
        onClose={() => setShowInfo(false)}
      >
        <SEO
          title="Nærmynd"
          description="Ýmsar sögur úr bransanum"
          og={seo_og}
        />
        <MobileStoryRoom 
          data={data}
        />
      </Layout>
    )
  }

}

export const query = graphql`
  query {
    ceiling: file(relativePath: { eq: "Loft-1.png" }) {
      childImageSharp {
        fluid(maxWidth: 1500, quality: 50) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    floor: file(relativePath: { eq: "squoosh_roomFloor.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1500, quality: 50) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    concreteWall: file(relativePath: { eq: "squoosh_roomWall.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1500, quality: 50) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    skyInWindow: file(relativePath: { eq: "thomas-vimare-IZ01rjX0XQA-unsplash.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1500, quality: 50) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    allStory (sort: {fields: order, order: ASC}){
      edges {
        node {
          speakerName
          companyName
          videolink
          order
          backgroundImage {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  } 
`