import React from 'react';
import styled from 'styled-components';

export interface StoryItemProps {
  xPos? : string,
  handleHover? : (event : any) => void,
  handleClick? : (event : any) => void,
  heightPercentage? : string,
  widthPercentage? : string,
  children?: any
}


export const StoryItem = ({ xPos, heightPercentage, widthPercentage, handleHover, handleClick, children } : StoryItemProps) => {
  
  return(
    <Item 
      heightPercentage={heightPercentage} 
      widthPercentage={widthPercentage} 
      xPos={xPos} 
      onMouseEnter={handleHover} 
      onClick={handleClick}
    >
      {children}
    </Item>
  )
}


const Item = styled.div<StoryItemProps>`
  height:${({ heightPercentage }) => heightPercentage};
  width:${({ widthPercentage }) => widthPercentage};
  pointer-events:auto;
  position:absolute;
  left:${({ xPos }) => xPos};
  transform: translate3d(0,0,5px);
  &:hover{
    cursor:pointer;
  }
`