import React from 'react'
import styled from 'styled-components'
import SideBannerImage from '../SideBanner/SideBannerImage'
import MobileStoryItem from './MobileStoryItem'

export default ({ data } : any) => {
	return (
		<MobileContainer>
      <MobileStoryItemContainer>
        <PageTitle>Nærmynd</PageTitle>
        {data.allStory.edges.map((story : any) => {
          console.log(story);
          return(
            <MobileStoryItem data={story.node} />
          )
        })}
      </MobileStoryItemContainer>
    </MobileContainer>
	)
}

const MobileStoryItemContainer = styled.div`
  width: 100%;
  padding: 0 25px;
`

const MobileContainer = styled.div`
  padding-bottom: 30px;
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
`

const PageTitle = styled.h1`
  font-size: max(22px, 5vw);
  color: black;
  width: 100%;
  text-align: Center;
  padding-top: 100px;
  padding-bottom: 30px;
  font-family: ${({ theme }) => theme.fonts.fontFamilyPrimary};
  margin-bottom: 30px;
`