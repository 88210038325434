import styled, { keyframes } from 'styled-components'
import BackgroundImage from 'gatsby-background-image'

interface GlitchProps {
  GlitchImage : string
}

/* VARIABLES FOR FULLSCREEN IMAGE */
const glitchVariablesLarge = {
  "color-text": "#fff",
  "color-bg": "#000",
  "color-link": "#f9d77e",
  "color-link-hover": "#fff",
  "color-info": "#efc453",
  "glitch-width": "100vw",
  "glitch-height": "100vh",
  "gap-horizontal": "10px",
  "gap-vertical": "5px",
  "time-anim": "10s",
  "delay-anim": "2s",
  "blend-mode-1": "none",
  "blend-mode-2": "none",
  "blend-mode-3": "none",
  "blend-mode-4": "none",
  "blend-mode-5": "overlay",
  "blend-color-1": "transparent",
  "blend-color-2": "transparent",
  "blend-color-3": "transparent",
  "blend-color-4": "transparent",
  "blend-color-5": "#fff",
}

/* ANIMATIONS */
export const glitchAnimation = () => (keyframes`
  0% { 
		opacity: 1;
		transform: translate3d(${glitchVariablesLarge["gap-horizontal"]},0,0);
		-webkit-clip-path: polygon(0 2%, 100% 2%, 100% 5%, 0 5%);
		clip-path: polygon(0 2%, 100% 2%, 100% 5%, 0 5%);
	}
	2% {
		-webkit-clip-path: polygon(0 15%, 100% 15%, 100% 15%, 0 15%);
		clip-path: polygon(0 15%, 100% 15%, 100% 15%, 0 15%);
	}
	3% {
		-webkit-clip-path: polygon(0 10%, 100% 10%, 100% 20%, 0 20%);
		clip-path: polygon(0 10%, 100% 10%, 100% 20%, 0 20%);
	}
	4% {
		-webkit-clip-path: polygon(0 1%, 100% 1%, 100% 2%, 0 2%);
		clip-path: polygon(0 1%, 100% 1%, 100% 2%, 0 2%);
	}
	5% {
		-webkit-clip-path: polygon(0 33%, 100% 33%, 100% 33%, 0 33%);
		clip-path: polygon(0 33%, 100% 33%, 100% 33%, 0 33%);
	}
	6% {
		-webkit-clip-path: polygon(0 44%, 100% 44%, 100% 44%, 0 44%);
		clip-path: polygon(0 44%, 100% 44%, 100% 44%, 0 44%);
	}
	8% {
		-webkit-clip-path: polygon(0 50%, 100% 50%, 100% 20%, 0 20%);
		clip-path: polygon(0 50%, 100% 50%, 100% 20%, 0 20%);
	}
	10% {
		-webkit-clip-path: polygon(0 70%, 100% 70%, 100% 70%, 0 70%);
		clip-path: polygon(0 70%, 100% 70%, 100% 70%, 0 70%);
	}
	12% {
		-webkit-clip-path: polygon(0 80%, 100% 80%, 100% 80%, 0 80%);
		clip-path: polygon(0 80%, 100% 80%, 100% 80%, 0 80%);
	}
	14% {
		-webkit-clip-path: polygon(0 50%, 100% 50%, 100% 55%, 0 55%);
		clip-path: polygon(0 50%, 100% 50%, 100% 55%, 0 55%);
	}
	16% {
		-webkit-clip-path: polygon(0 70%, 100% 70%, 100% 80%, 0 80%);
		clip-path: polygon(0 70%, 100% 70%, 100% 80%, 0 80%);
	}
	17.9% {
		opacity: 1;
		transform: translate3d(${glitchVariablesLarge["gap-horizontal"]},0,0);
	}
	18%, 100% {
		opacity: 0;
		transform: translate3d(0,0,0);
		-webkit-clip-path: polygon(0 0, 0 0, 0 0, 0 0);
		clip-path: polygon(0 0, 0 0, 0 0, 0 0);
	}
`)

export const glitchAnimationSecond = () => (keyframes`
  0% { 
		opacity: 1;
		transform: translate3d(calc(-1 * ${glitchVariablesLarge["gap-horizontal"]}),0,0);
		-webkit-clip-path: polygon(0 25%, 100% 25%, 100% 30%, 0 30%);
		clip-path: polygon(0 25%, 100% 25%, 100% 30%, 0 30%);
	}
	3% {
		-webkit-clip-path: polygon(0 3%, 100% 3%, 100% 3%, 0 3%);
		clip-path: polygon(0 3%, 100% 3%, 100% 3%, 0 3%);
	}
	4% {
		-webkit-clip-path: polygon(0 5%, 100% 5%, 100% 20%, 0 20%);
		clip-path: polygon(0 5%, 100% 5%, 100% 20%, 0 20%);
	}
	5% {
		-webkit-clip-path: polygon(0 20%, 100% 20%, 100% 20%, 0 20%);
		clip-path: polygon(0 20%, 100% 20%, 100% 20%, 0 20%);
	}
	6% {
		-webkit-clip-path: polygon(0 40%, 100% 40%, 100% 40%, 0 40%);
		clip-path: polygon(0 40%, 100% 40%, 100% 40%, 0 40%);
	}
	8% {
		-webkit-clip-path: polygon(0 52%, 100% 52%, 100% 59%, 0 59%);
		clip-path: polygon(0 52%, 100% 52%, 100% 59%, 0 59%);
	}
	10% {
		-webkit-clip-path: polygon(0 60%, 100% 60%, 100% 60%, 0 60%);
		clip-path: polygon(0 60%, 100% 60%, 100% 60%, 0 60%);
	}
	11% {
		-webkit-clip-path: polygon(0 75%, 100% 75%, 100% 75%, 0 75%);
		clip-path: polygon(0 75%, 100% 75%, 100% 75%, 0 75%);
	}
	12% {
		-webkit-clip-path: polygon(0 65%, 100% 65%, 100% 40%, 0 40%);
		clip-path: polygon(0 65%, 100% 65%, 100% 40%, 0 40%);
	}
	14% {
		-webkit-clip-path: polygon(0 45%, 100% 45%, 100% 50%, 0 50%);
		clip-path: polygon(0 45%, 100% 45%, 100% 50%, 0 50%);
	}
	16% {
		-webkit-clip-path: polygon(0 14%, 100% 14%, 100% 33%, 0 33%);
		clip-path: polygon(0 14%, 100% 14%, 100% 33%, 0 33%);
	}
	17.9% {
		opacity: 1;
		transform: translate3d(calc(-1 * ${glitchVariablesLarge["gap-horizontal"]}),0,0);
	}
	18%, 100% {
		opacity: 0;
		transform: translate3d(0,0,0);
		-webkit-clip-path: polygon(0 0, 0 0, 0 0, 0 0);
		clip-path: polygon(0 0, 0 0, 0 0, 0 0);
	}
`)

export const glitchAnimationThird = () => (keyframes`
  0% { 
		opacity: 1;
		transform: translate3d(0, calc(-1 * ${glitchVariablesLarge["gap-vertical"]}), 0) scale3d(-1,-1,1);
		-webkit-clip-path: polygon(0 1%, 100% 1%, 100% 3%, 0 3%);
		clip-path: polygon(0 1%, 100% 1%, 100% 3%, 0 3%);
	}
	1.5% {
		-webkit-clip-path: polygon(0 10%, 100% 10%, 100% 9%, 0 9%);
		clip-path: polygon(0 10%, 100% 10%, 100% 9%, 0 9%);
	}
	2% {
		-webkit-clip-path: polygon(0 5%, 100% 5%, 100% 6%, 0 6%);
		clip-path: polygon(0 5%, 100% 5%, 100% 6%, 0 6%);
	}
	2.5% {
		-webkit-clip-path: polygon(0 20%, 100% 20%, 100% 20%, 0 20%);
		clip-path: polygon(0 20%, 100% 20%, 100% 20%, 0 20%);
	}
	3% {
		-webkit-clip-path: polygon(0 10%, 100% 10%, 100% 10%, 0 10%);
		clip-path: polygon(0 10%, 100% 10%, 100% 10%, 0 10%);
	}
	4% {
		-webkit-clip-path: polygon(0 30%, 100% 30%, 100% 25%, 0 25%);
		clip-path: polygon(0 30%, 100% 30%, 100% 25%, 0 25%);
	}
	4.5% {
		-webkit-clip-path: polygon(0 15%, 100% 15%, 100% 16%, 0 16%);
		clip-path: polygon(0 15%, 100% 15%, 100% 16%, 0 16%);
	}
	5% {
		-webkit-clip-path: polygon(0 40%, 100% 40%, 100% 39%, 0 39%);
		clip-path: polygon(0 40%, 100% 40%, 100% 39%, 0 39%);
	}
	6% {
		-webkit-clip-path: polygon(0 20%, 100% 20%, 100% 21%, 0 21%);
		clip-path: polygon(0 20%, 100% 20%, 100% 21%, 0 21%);
	}
	7% {
		-webkit-clip-path: polygon(0 60%, 100% 60%, 100% 55%, 0 55%);
		clip-path: polygon(0 60%, 100% 60%, 100% 55%, 0 55%);
	}
	8.5% {
		-webkit-clip-path: polygon(0 30%, 100% 30%, 100% 31%, 0 31%);
		clip-path: polygon(0 30%, 100% 30%, 100% 31%, 0 31%);
	}
	9% {
		-webkit-clip-path: polygon(0 70%, 100% 70%, 100% 69%, 0 69%);
		clip-path: polygon(0 70%, 100% 70%, 100% 69%, 0 69%);
	}
	10% {
		-webkit-clip-path: polygon(0 40%, 100% 40%, 100% 41%, 0 41%);
		clip-path: polygon(0 40%, 100% 40%, 100% 41%, 0 41%);
	}
	12% {
		-webkit-clip-path: polygon(0 80%, 100% 80%, 100% 75%, 0 75%);
		clip-path: polygon(0 80%, 100% 80%, 100% 75%, 0 75%);
	}
	13.5% {
		-webkit-clip-path: polygon(0 50%, 100% 50%, 100% 51%, 0 51%);
		clip-path: polygon(0 50%, 100% 50%, 100% 51%, 0 51%);
	}
	14% {
		-webkit-clip-path: polygon(0 90%, 100% 90%, 100% 90%, 0 90%);
		clip-path: polygon(0 90%, 100% 90%, 100% 90%, 0 90%);
	}
	15% {
		-webkit-clip-path: polygon(0 60%, 100% 60%, 100% 60%, 0 60%);
		clip-path: polygon(0 60%, 100% 60%, 100% 60%, 0 60%);
	}
	16% {
		-webkit-clip-path: polygon(0 100%, 100% 100%, 100% 99%, 0 99%);
		clip-path: polygon(0 100%, 100% 100%, 100% 99%, 0 99%);
	}
	17% {
		-webkit-clip-path: polygon(0 70%, 100% 70%, 100% 71%, 0 71%);
		clip-path: polygon(0 70%, 100% 70%, 100% 71%, 0 71%);
	}
	17.9% {
		opacity: 1;
		transform: translate3d(0, calc(-1 * ${glitchVariablesLarge["gap-vertical"]}), 0) scale3d(-1,-1,1);
	}
	18%, 100% {
		opacity: 0;
		transform: translate3d(0,0,0);
		-webkit-clip-path: polygon(0 0, 0 0, 0 0, 0 0);
		clip-path: polygon(0 0, 0 0, 0 0, 0 0);
	}
`)

export const glitchAnimationText = () => (keyframes`
  0% { 
		transform: translate3d(calc(-1 * ${glitchVariablesLarge["gap-horizontal"]}),0,0) scale3d(-1,-1,1);
		-webkit-clip-path: polygon(0 20%, 100% 20%, 100% 21%, 0 21%);
		clip-path: polygon(0 20%, 100% 20%, 100% 21%, 0 21%);
	}
	2% {
		-webkit-clip-path: polygon(0 33%, 100% 33%, 100% 33%, 0 33%);
		clip-path: polygon(0 33%, 100% 33%, 100% 33%, 0 33%);
	}
	4% {
		-webkit-clip-path: polygon(0 44%, 100% 44%, 100% 44%, 0 44%);
		clip-path: polygon(0 44%, 100% 44%, 100% 44%, 0 44%);
	}
	5% {
		-webkit-clip-path: polygon(0 50%, 100% 50%, 100% 20%, 0 20%);
		clip-path: polygon(0 50%, 100% 50%, 100% 20%, 0 20%);
	}
	6% {
		-webkit-clip-path: polygon(0 70%, 100% 70%, 100% 70%, 0 70%);
		clip-path: polygon(0 70%, 100% 70%, 100% 70%, 0 70%);
	}
	7% {
		-webkit-clip-path: polygon(0 80%, 100% 80%, 100% 80%, 0 80%);
		clip-path: polygon(0 80%, 100% 80%, 100% 80%, 0 80%);
	}
	8% {
		-webkit-clip-path: polygon(0 50%, 100% 50%, 100% 55%, 0 55%);
		clip-path: polygon(0 50%, 100% 50%, 100% 55%, 0 55%);
	}
	9% {
		-webkit-clip-path: polygon(0 70%, 100% 70%, 100% 80%, 0 80%);
		clip-path: polygon(0 70%, 100% 70%, 100% 80%, 0 80%);
	}
	9.9% {
		transform: translate3d(calc(-1 * ${glitchVariablesLarge["gap-horizontal"]}),0,0) scale3d(-1,-1,1);
	}
	10%, 100% {
		transform: translate3d(0,0,0) scale3d(1,1,1);
		-webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
		clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
	}
`)

export const glitchAnimFlash = () => (keyframes`
  0% { 
		opacity: 0.2; 
		transform: translate3d(${glitchVariablesLarge["gap-horizontal"]}, ${glitchVariablesLarge["gap-vertical"]}, 0);
	}
	22%, 100% { 
		opacity: 0;
		transform: translate3d(0,0,0);
	}
`)

export const GlitchLarge = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: ${glitchVariablesLarge["glitch-width"]};
  height: ${glitchVariablesLarge["glitch-height"]};
  overflow: hidden;
`

export const GlitchImageLarge = styled(BackgroundImage)`
  position: absolute !important;
  top: calc(-1 *  ${glitchVariablesLarge["gap-vertical"]});
  left: calc(-1 *  ${glitchVariablesLarge["gap-horizontal"]});
  width: calc(100% + ${glitchVariablesLarge["gap-horizontal"]} * 2);
  height: calc(100% + ${glitchVariablesLarge["gap-vertical"]} * 2);
  background-color: ${glitchVariablesLarge["blend-color-1"]};
  background-size: cover;
  transform: translate3d(0,0,0);
  background-blend-mode: ${glitchVariablesLarge["blend-mode-1"]};

  &:nth-child(1) {
    background-color: ${glitchVariablesLarge["blend-color-2"]};
    background-blend-mode: ${glitchVariablesLarge["blend-mode-2"]};
    animation-name: ${glitchAnimation};
  }

  &:nth-child(2) {
    background-color: ${glitchVariablesLarge["blend-color-3"]};
    background-blend-mode: ${glitchVariablesLarge["blend-mode-3"]};
    animation-name: ${glitchAnimationSecond};
  }

  &:nth-child(3) {
    background-color: ${glitchVariablesLarge["blend-color-4"]};
    background-blend-mode: ${glitchVariablesLarge["blend-mode-4"]};
    animation-name: ${glitchAnimationThird};
  }

  &:nth-child(4) {
    background-color: ${glitchVariablesLarge["blend-color-5"]};
    background-blend-mode: ${glitchVariablesLarge["blend-mode-5"]};
    animation-name: ${glitchAnimFlash};
  }

  /* Hide all images except the first one */
  &:nth-child(1), &:nth-child(2), &:nth-child(3), &:nth-child(4) {
    animation-duration:  ${glitchVariablesLarge["time-anim"]};
    animation-delay: ${glitchVariablesLarge["delay-anim"]};
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    opacity:0;
  }

  &:nth-child(5){
    display:none;
    /* &:after, &:before{
      background-size:auto !important;
    } */
  }
`

/* ANIMATIONS FOR SMALL IMAGES */

export const glitchAnimText = () => (keyframes`
  0% { 
		opacity: 1;
		transform: translate3d(-10px,0,0) scale3d(-1,-1,1);
		-webkit-clip-path: polygon(0 20%, 100% 20%, 100% 21%, 0 21%);
		clip-path: polygon(0 20%, 100% 20%, 100% 21%, 0 21%);
	}
	10% {
		-webkit-clip-path: polygon(0 33%, 100% 33%, 100% 33%, 0 33%);
		clip-path: polygon(0 33%, 100% 33%, 100% 33%, 0 33%);
	}
	20% {
		-webkit-clip-path: polygon(0 44%, 100% 44%, 100% 44%, 0 44%);
		clip-path: polygon(0 44%, 100% 44%, 100% 44%, 0 44%);
	}
	35% {
		-webkit-clip-path: polygon(0 50%, 100% 50%, 100% 20%, 0 20%);
		clip-path: polygon(0 50%, 100% 50%, 100% 20%, 0 20%);
	}
	50% {
		-webkit-clip-path: polygon(0 70%, 100% 70%, 100% 70%, 0 70%);
		clip-path: polygon(0 70%, 100% 70%, 100% 70%, 0 70%);
	}
	60% {
		-webkit-clip-path: polygon(0 80%, 100% 80%, 100% 80%, 0 80%);
		clip-path: polygon(0 80%, 100% 80%, 100% 80%, 0 80%);
	}
	70% {
		-webkit-clip-path: polygon(0 50%, 100% 50%, 100% 55%, 0 55%);
		clip-path: polygon(0 50%, 100% 50%, 100% 55%, 0 55%);
	}
	80% {
		-webkit-clip-path: polygon(0 70%, 100% 70%, 100% 80%, 0 80%);
		clip-path: polygon(0 70%, 100% 70%, 100% 80%, 0 80%);
	}
	90% {
		transform: translate3d(-10px,0,0) scale3d(-1,-1,1);
	}
	100% {
		opacity: 1;
		transform: translate3d(0,0,0) scale3d(1,1,1);
		-webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
		clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
	}
`) 

export const glitchAnimHorizontalFirst = () => (keyframes`
	0% { 
		-webkit-clip-path: polygon(0 25%, 100% 25%, 100% 30%, 0 30%);
		clip-path: polygon(0 25%, 100% 25%, 100% 30%, 0 30%);
	}
	15% {
		-webkit-clip-path: polygon(0 3%, 100% 3%, 100% 3%, 0 3%);
		clip-path: polygon(0 3%, 100% 3%, 100% 3%, 0 3%);
	}
	22% {
		-webkit-clip-path: polygon(0 5%, 100% 5%, 100% 20%, 0 20%);
		clip-path: polygon(0 5%, 100% 5%, 100% 20%, 0 20%);
	}
	31% {
		-webkit-clip-path: polygon(0 20%, 100% 20%, 100% 20%, 0 20%);
		clip-path: polygon(0 20%, 100% 20%, 100% 20%, 0 20%);
	}
	45% {
		-webkit-clip-path: polygon(0 40%, 100% 40%, 100% 40%, 0 40%);
		clip-path: polygon(0 40%, 100% 40%, 100% 40%, 0 40%);
	}
	51% {
		-webkit-clip-path: polygon(0 52%, 100% 52%, 100% 59%, 0 59%);
		clip-path: polygon(0 52%, 100% 52%, 100% 59%, 0 59%);
	}
	63% {
		-webkit-clip-path: polygon(0 60%, 100% 60%, 100% 60%, 0 60%);
		clip-path: polygon(0 60%, 100% 60%, 100% 60%, 0 60%);
	}
	76% {
		-webkit-clip-path: polygon(0 75%, 100% 75%, 100% 75%, 0 75%);
		clip-path: polygon(0 75%, 100% 75%, 100% 75%, 0 75%);
	}
	81% {
		-webkit-clip-path: polygon(0 65%, 100% 65%, 100% 40%, 0 40%);
		clip-path: polygon(0 65%, 100% 65%, 100% 40%, 0 40%);
	}
	94% {
		-webkit-clip-path: polygon(0 45%, 100% 45%, 100% 50%, 0 50%);
		clip-path: polygon(0 45%, 100% 45%, 100% 50%, 0 50%);
	}
	100% {
		-webkit-clip-path: polygon(0 14%, 100% 14%, 100% 33%, 0 33%);
		clip-path: polygon(0 14%, 100% 14%, 100% 33%, 0 33%);
  }
`)

export const glitchAnimHorizontalSecond = () => (keyframes`
	0% { 
		-webkit-clip-path: polygon(0 25%, 100% 25%, 100% 30%, 0 30%);
		clip-path: polygon(0 25%, 100% 25%, 100% 30%, 0 30%);
	}
	15% {
		-webkit-clip-path: polygon(0 3%, 100% 3%, 100% 3%, 0 3%);
		clip-path: polygon(0 3%, 100% 3%, 100% 3%, 0 3%);
	}
	22% {
		-webkit-clip-path: polygon(0 5%, 100% 5%, 100% 20%, 0 20%);
		clip-path: polygon(0 5%, 100% 5%, 100% 20%, 0 20%);
	}
	31% {
		-webkit-clip-path: polygon(0 20%, 100% 20%, 100% 20%, 0 20%);
		clip-path: polygon(0 20%, 100% 20%, 100% 20%, 0 20%);
	}
	45% {
		-webkit-clip-path: polygon(0 40%, 100% 40%, 100% 40%, 0 40%);
		clip-path: polygon(0 40%, 100% 40%, 100% 40%, 0 40%);
	}
	51% {
		-webkit-clip-path: polygon(0 52%, 100% 52%, 100% 59%, 0 59%);
		clip-path: polygon(0 52%, 100% 52%, 100% 59%, 0 59%);
	}
	63% {
		-webkit-clip-path: polygon(0 60%, 100% 60%, 100% 60%, 0 60%);
		clip-path: polygon(0 60%, 100% 60%, 100% 60%, 0 60%);
	}
	76% {
		-webkit-clip-path: polygon(0 75%, 100% 75%, 100% 75%, 0 75%);
		clip-path: polygon(0 75%, 100% 75%, 100% 75%, 0 75%);
	}
	81% {
		-webkit-clip-path: polygon(0 65%, 100% 65%, 100% 40%, 0 40%);
		clip-path: polygon(0 65%, 100% 65%, 100% 40%, 0 40%);
	}
	94% {
		-webkit-clip-path: polygon(0 45%, 100% 45%, 100% 50%, 0 50%);
		clip-path: polygon(0 45%, 100% 45%, 100% 50%, 0 50%);
	}
	100% {
		-webkit-clip-path: polygon(0 14%, 100% 14%, 100% 33%, 0 33%);
		clip-path: polygon(0 14%, 100% 14%, 100% 33%, 0 33%);
	}
`)

export const glitchAnimHorizontalThird = () => (keyframes`
	0% { 
		-webkit-clip-path: polygon(0 1%, 100% 1%, 100% 3%, 0 3%);
		clip-path: polygon(0 1%, 100% 1%, 100% 3%, 0 3%);
	}
	5% {
		-webkit-clip-path: polygon(0 10%, 100% 10%, 100% 9%, 0 9%);
		clip-path: polygon(0 10%, 100% 10%, 100% 9%, 0 9%);
	}
	10% {
		-webkit-clip-path: polygon(0 5%, 100% 5%, 100% 6%, 0 6%);
		clip-path: polygon(0 5%, 100% 5%, 100% 6%, 0 6%);
	}
	25% {
		-webkit-clip-path: polygon(0 20%, 100% 20%, 100% 20%, 0 20%);
		clip-path: polygon(0 20%, 100% 20%, 100% 20%, 0 20%);
	}
	27% {
		-webkit-clip-path: polygon(0 10%, 100% 10%, 100% 10%, 0 10%);
		clip-path: polygon(0 10%, 100% 10%, 100% 10%, 0 10%);
	}
	30% {
		-webkit-clip-path: polygon(0 30%, 100% 30%, 100% 25%, 0 25%);
		clip-path: polygon(0 30%, 100% 30%, 100% 25%, 0 25%);
	}
	33% {
		-webkit-clip-path: polygon(0 15%, 100% 15%, 100% 16%, 0 16%);
		clip-path: polygon(0 15%, 100% 15%, 100% 16%, 0 16%);
	}
	37% {
		-webkit-clip-path: polygon(0 40%, 100% 40%, 100% 39%, 0 39%);
		clip-path: polygon(0 40%, 100% 40%, 100% 39%, 0 39%);
	}
	40% {
		-webkit-clip-path: polygon(0 20%, 100% 20%, 100% 21%, 0 21%);
		clip-path: polygon(0 20%, 100% 20%, 100% 21%, 0 21%);
	}
	45% {
		-webkit-clip-path: polygon(0 60%, 100% 60%, 100% 55%, 0 55%);
		clip-path: polygon(0 60%, 100% 60%, 100% 55%, 0 55%);
	}
	50% {
		-webkit-clip-path: polygon(0 30%, 100% 30%, 100% 31%, 0 31%);
		clip-path: polygon(0 30%, 100% 30%, 100% 31%, 0 31%);
	}
	53% {
		-webkit-clip-path: polygon(0 70%, 100% 70%, 100% 69%, 0 69%);
		clip-path: polygon(0 70%, 100% 70%, 100% 69%, 0 69%);
	}
	57% {
		-webkit-clip-path: polygon(0 40%, 100% 40%, 100% 41%, 0 41%);
		clip-path: polygon(0 40%, 100% 40%, 100% 41%, 0 41%);
	}
	60% {
		-webkit-clip-path: polygon(0 80%, 100% 80%, 100% 75%, 0 75%);
		clip-path: polygon(0 80%, 100% 80%, 100% 75%, 0 75%);
	}
	65% {
		-webkit-clip-path: polygon(0 50%, 100% 50%, 100% 51%, 0 51%);
		clip-path: polygon(0 50%, 100% 50%, 100% 51%, 0 51%);
	}
	70% {
		-webkit-clip-path: polygon(0 90%, 100% 90%, 100% 90%, 0 90%);
		clip-path: polygon(0 90%, 100% 90%, 100% 90%, 0 90%);
	}
	73% {
		-webkit-clip-path: polygon(0 60%, 100% 60%, 100% 60%, 0 60%);
		clip-path: polygon(0 60%, 100% 60%, 100% 60%, 0 60%);
	}
	80% {
		-webkit-clip-path: polygon(0 100%, 100% 100%, 100% 99%, 0 99%);
		clip-path: polygon(0 100%, 100% 100%, 100% 99%, 0 99%);
	}
	100% {
		-webkit-clip-path: polygon(0 70%, 100% 70%, 100% 71%, 0 71%);
		clip-path: polygon(0 70%, 100% 70%, 100% 71%, 0 71%);
	}
`)

/* VARIABLES FOR SMALL IMAGES */

const glitchVariablesSmall = {
  "gap-horizontal" : "5px",
	"gap-vertical" : "10px",
	"time-anim" : "2s",
	"blend-mode-1" : "none",
	"blend-mode-2" : "none",
	"blend-mode-3" : "luminosity",
	"blend-mode-4" : "none",
	"blend-mode-5" : "none",
  "blend-color-1" : "transparent",
	"blend-color-2" : "transparent",
	"blend-color-3" : "#4d8c60",
	"blend-color-4" : "transparent",
  "blend-color-5" : "#c9b09a",
  "color-text" : "#454847",
	"color-bg" : "#fff",
	"color-link" : "#454847",
	"color-link-hover" : "#fff",
	"color-info" : "#454847",
  "glitch-width" : "40vmax",
	"glitch-height" : "calc(40vmax * 1.25)",
	"color-title" : "#fff",
	"color-subtitle":" #30efbf",
}

/* STYLES */
export const GlitchSmall = styled.div`
  position: relative;
	width:100%;
  height:100%;
	overflow: hidden;
  margin: 0 auto;
  
  &:hover {
    cursor: pointer;
    > div:nth-child(1), div:nth-child(2), div:nth-child(3), div:nth-child(4), div:nth-child(5) {
      opacity: 1;
    }

    > div:nth-child(1){
      transform: translate3d(${glitchVariablesSmall["gap-horizontal"]},0,0);
	    animation: ${glitchAnimHorizontalFirst} ${glitchVariablesSmall["time-anim"]} infinite linear alternate;
    }

    > div:nth-child(2){
      transform: translate3d(calc(-1 * ${glitchVariablesSmall["gap-horizontal"]}),0,0);
	    animation: ${glitchAnimHorizontalSecond} ${glitchVariablesSmall["time-anim"]} infinite linear alternate;
    }

    > div:nth-child(3) {
      transform: translate3d(0, calc(-1 * ${glitchVariablesSmall["gap-vertical"]}), 0) scale3d(-1,-1,1);
      animation: ${glitchAnimHorizontalThird} ${glitchVariablesSmall["time-anim"]} infinite linear alternate;
    }

    /* Hover flash animation on last image */
    > div:nth-child(4) {
      animation: ${glitchAnimFlash} 0.5s steps(1,end) infinite;
    }
  }
`

export const GlitchImageSmall = styled(BackgroundImage)`
	position: absolute !important;
	top: calc(-1 * ${glitchVariablesSmall["gap-vertical"]});
	left: calc(-1 * ${glitchVariablesSmall["gap-horizontal"]});
	width: calc(100% + ${glitchVariablesSmall["gap-horizontal"]} * 2);
	height: calc(100% + ${glitchVariablesSmall["gap-vertical"]} * 2);
	background-color: ${glitchVariablesSmall["blend-color-1"]};
	background-size: cover;
	transform: translate3d(0,0,0);
	background-blend-mode: ${glitchVariablesSmall["blend-mode-1"]};
  
  	&:nth-child(1) {
		background-color: ${glitchVariablesSmall["blend-color-2"]};
		background-blend-mode: ${glitchVariablesSmall["blend-mode-2"]};
	}

	&:nth-child(2) {
		background-color: ${glitchVariablesSmall["blend-color-3"]};
		background-blend-mode: ${glitchVariablesSmall["blend-mode-3"]};
	}

	&:nth-child(3) {
		background-color: ${glitchVariablesSmall["blend-color-4"]};
		background-blend-mode: ${glitchVariablesSmall["blend-mode-4"]};
	}

	&:nth-child(4) {
		background-color: ${glitchVariablesSmall["blend-color-5"]};
		background-blend-mode: ${glitchVariablesSmall["blend-mode-5"]};
	}

	&:nth-child(1), &:nth-child(2), &:nth-child(3), &:nth-child(4) {
		opacity:0;
	}

	&:nth-child(5){
		display:none;
		/* &:after, &:before{
		background-size:auto !important;
		} */
	}
`

