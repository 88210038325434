import React from 'react';
import { GlitchSmall, GlitchImageSmall, GlitchLarge, GlitchImageLarge } from '../../styles/glitchAnimations';
import Img from 'gatsby-image';
import styled from 'styled-components';

interface GlitchEffectProps{
  small? : boolean,
  backgroundImage? : any,
  fluidImage?: any,
  children? : any
}

export const GlitchEffect = ({ small, backgroundImage, fluidImage, children } : GlitchEffectProps) => {
  if(small){
    return(
      <>
        <StyledOrgImage fluid={backgroundImage} />
        <GlitchSmall>
          <GlitchImageSmall 
            fluid={backgroundImage} 
            ></GlitchImageSmall>
          <GlitchImageSmall 
            fluid={backgroundImage} 
            ></GlitchImageSmall>
            <GlitchImageSmall 
            fluid={backgroundImage} 
            ></GlitchImageSmall>
            <GlitchImageSmall 
            fluid={backgroundImage} 
            ></GlitchImageSmall>
            <GlitchImageSmall 
            fluid={backgroundImage} 
            ></GlitchImageSmall>
            {children}
        </GlitchSmall>
      </>
    )
  }else{
    return(
      <GlitchLarge>
        <GlitchImageLarge 
          fluid={fluidImage.childImageSharp.fluid}
          ></GlitchImageLarge>
        <GlitchImageLarge 
          fluid={fluidImage.childImageSharp.fluid} 
          ></GlitchImageLarge>
          <GlitchImageLarge 
          fluid={fluidImage.childImageSharp.fluid} 
          ></GlitchImageLarge>
          <GlitchImageLarge 
          fluid={fluidImage.childImageSharp.fluid} 
          ></GlitchImageLarge>
          <GlitchImageLarge 
          fluid={fluidImage.childImageSharp.fluid} 
          ></GlitchImageLarge>
      </GlitchLarge>
    )
  }
}

const StyledOrgImage = styled(Img)`
  height:100%;
  width:100%;
  position:absolute !important;
`